import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusText, TextEdges } from './text.interface';

export default (): TextEdges[] => {
  const { allDirectusText } = useStaticQuery<AllDirectusText>(graphql`
    query {
      allDirectusText {
        edges {
          node {
            directusId
            slug
            body
          }
        }
      }
    }
  `);

  return allDirectusText.edges;
};
