import React from 'react';

import Markdown from 'components/common/markdown/Markdown';
import textHook from 'hooks/text/text.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { Props } from './Text.interface';

import { margin as marginClass, marginBottom as marginBottomClass, body as bodyClass } from './Text.scss';

export default ({ slug, margin, baseFontSize, listItemIcon, marginBottom }: Props): JSX.Element => {
  const { body } = getFieldBySlug(slug, textHook()).node;
  const marginStyle = margin ? marginClass : marginBottom ? marginBottomClass : undefined;

  return (
    <div className={marginStyle} style={{ fontSize: baseFontSize }}>
      <Markdown source={body} className={bodyClass} listItemIcon={listItemIcon} container />
    </div>
  );
};
