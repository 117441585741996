import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import Button from 'components/common/button/Button';
import Text from 'components/directus/text/Text';

import { affiliateContainer, affiliateContainer__button } from './AffiliateProgram.scss';

export default () => {
  const slug = 'affiliate-program';
  return (
    <div className={affiliateContainer}>
      <Text slug={slug} listItemIcon={<FontAwesomeIcon icon={faChevronRight} size="sm" aria-label="check icon." />} />
      <Button
        to={`${process.env.GATSBY_AFFILIATE_PROGRAM_LINK}`}
        role="primary"
        size="large"
        className={affiliateContainer__button}
      >
        Let&apos;s get started
      </Button>
    </div>
  );
};
